import './NavSuperior.css';
import { Link, NavLink } from 'react-router-dom';

const NavSuperior = () => {
  return (
    <>
      
      <div className='nav-bar'>
        <div className="nav-links">
          <ul>
            <li><a href="https://www.asijemin.org.ar" Target="_blank">asijemin.org.ar</a></li>
            <li><a href="https://www.asijemin.org.ar" Target="_blank">mis datos</a></li>
          </ul>
        </div>

        <div className="nav-social">
          <ul className='cont-redes'>
            <li className='facebook'>
              <a href="https://facebook.com"><i className="fab fa-facebook-square redesociales"></i></a>
            </li>
            <li className='instagram'>
              <a href="https://instagram.com"><i className="fab fa-instagram-square redesociales"></i></a>
            </li>

            <li className='Twitter'>
              <a href="https://twitter.com"><i class="fa-brands fa-square-x-twitter redesociales"></i></a>
            </li>
            
          </ul>
        </div>
      </div>
    </>
  )
}

export default NavSuperior;
