import { Helmet } from 'react-helmet'
import Principales from '../Principales/Principales'
import './Contenedor.css'
import BreackNews from '../BreakNews/BreackNews'

const Contenedor = () => {
  return (
    <>
      <Helmet>
        <title>Inicio | Accion Social ASIJEMIN</title>
      </Helmet>
      
      <BreackNews />
      <Principales/>
    </>
  )
}

export default Contenedor
