import React, { useEffect, useState } from "react";
import axios from 'axios';
import "./AnuncionVarios.css";

const AnuncionVarios = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Realiza una solicitud GET a la API
    axios.get('http://149.50.129.191:4000/convenios')
      .then(response => {
        // Filtra los datos para mostrar solo los destacados (destacado = 1)
        const destacados = response.data.message.filter(item => item.destacado == 1);
        // Almacena los datos filtrados en el estado
        setData(destacados);
      })
      .catch(error => {
        console.error('Error al obtener datos:', error);
      });
  }, []);

  return (
    <div className="row">
      {data.map(item => (
        <div className="col-12 col-md-4 mb-4" key={item._id}>
          <div className="card card-anuncios mx-2 w-100" style={{ maxWidth: "427px" }}>
            <img
              src="./image/03.jpg"
              className="card-img-top card-img"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">
                {item.nombre}
              </h5>
              <p className="card-text">
                {item.descripcion}
              </p>
            </div>
            <div className="card-body">
              <a href="https://www.asijemin.org.ar" className="card-link">
                Leer Más
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnuncionVarios;
