import  './Footer.css'



const Footer = () => {
  return (
    <>
     <div class="container-fluid">
     <div className="row rowfooter">
            
                <div className="col-3 seciones-footer">
                        <h4>Titulo de la Seccion</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita similique hic ducimus ut excepturi corrupti sequi placeat. Quos nulla voluptates, libero totam minima consequatur iure ullam nihil sapiente ipsa inventore.</p>
                </div>

                <div className="col-4 seciones-footer">
                        <h4>Titulo de la Seccion</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita similique hic ducimus ut excepturi corrupti sequi placeat. Quos nulla voluptates, libero totam minima consequatur iure ullam nihil sapiente ipsa inventore.</p>
                </div>

                <div className="col-5 seciones-footer">
                        <h4>Titulo de la Seccion</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita similique hic ducimus ut excepturi corrupti sequi placeat. Quos nulla voluptates, libero totam minima consequatur iure ullam nihil sapiente ipsa inventore.</p>
                </div>
        </div>

        <div className="row">
                <div className="col-12 derechosr">
                        <p>Todos los derechos reservados ASIJEMIN 2023</p>
                </div>
        </div>
    </div>

        
     
    </>
  )
}

export default Footer
