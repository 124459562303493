import AnuncionVarios from '../AnuncionVarios/AnuncionVarios'
import './MarcoContainer.css'

const MarcoContainer = () => {
  return (
    <>
         <h2 className='titulo-seccion-anuncios'>Listado de Convenios</h2>
         <p className='parrafo-seccion-anuncios'>Todos nuestros beneficios</p>
    <div className="section-anuncios">

             <div className="anuncios">
                <AnuncionVarios />
            </div>
      
            <div className="laterales">
                  <div className="notlat">
                    <p>aviso</p>
                  </div>

                  <div className="notlat2">
                    <p>aviso2</p>
                  </div>

                  <div className="notlat3">
                    <p>aviso3</p>
                  </div>
            </div>

            
                 
           

    </div>

  
    </>
  )
}

export default MarcoContainer
