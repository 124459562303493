import React, { useState, useEffect } from 'react';
import './BreakNews.css';

const BreackNews = () => {
  const [showFirstText, setShowFirstText] = useState(true);

  useEffect(() => {
    const toggleText = () => {
      setShowFirstText((prev) => !prev);
    };

    const interval = setInterval(toggleText, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='breack-news d-flex align-items-center'>
      <div className="pl-2">
        <p className="falsobtn">Tendencia:</p>
      </div>
      
      <div className={`m-0 ml-3 texto-desliza ${showFirstText ? 'show' : ''}`}>
        {showFirstText ? 'Noticias en tendencia van en este div' : 'Este es el segundo breackin'}
      </div>

      <div className="btn-group" role="group" aria-label="Button group with nested">
        <button type="button" className="btn btn-light btn-sm"><i className="fa-solid fa-circle-left"></i></button>
        <button type="button" className="btn btn-light btn-sm"><i className="fa-solid fa-circle-right"></i></button>
      </div>
    </div>
  );
}

export default BreackNews;
