import './TopFive.css'

const TopFive = () => {
  return (
    <div>
      <div className="contenedortop5">
        <div className="cont1">
          <img src="./image/01.jpg" alt="" />
          
          <p className='etiqueta-img'><i class="fas fa-circle me-2 small fw-bold"></i>Viajes</p>
            <h2 className='title-noticia'>lugares recomendados para este mes</h2>
          <p className='descripcion-notas'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut, sapiente provident incidunt accusamus accusantium a dignissimos quibusdam laudantium enim nam!</p>
        </div>

        <div className="cont2">
          <div className="avsuperior">
          <img src="./image/02.jpg" alt="" />
          <p className='etiqueta-imgsuperior'><i class="fas fa-circle me-2 small fw-bold"></i>Destinos</p>
            <h2 className='title-noticia-superior'>lugares recomendados para este mes</h2>
          <p className='descripcion-notas-superior'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut, sapiente provident incidunt accusamus accusantium a dignissimos quibusdam laudantium enim nam!</p>
          </div>


          <div className="infder">
          <img src="./image/04.jpg" alt="" />
          <p className='etiqueta-infder'><i class="fas fa-circle me-2 small fw-bold"></i>Nuevo </p>
          <h2 className='title-noticia-derecha'>titulo destino lugar 3</h2>
          </div>


          <div className="iniz">
          <img src="./image/05.jpg" alt="" />
          <p className='etiqueta-infiz'><i class="fas fa-circle me-2 small fw-bold"></i>Reserva ya</p>
          <h2 className='title-noticia-izquierda'>titulo destino lugar 4</h2>
          </div>
        </div>
            
             

      </div>
    </div>
  )
}

export default TopFive
