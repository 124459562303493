
import './App.css';
import Comunicados from './components/Comunicados/Comunicados';
import Contenedor from './components/Contenedor/Contenedor';
import Footer from './components/Footer/Footer';
import NavSuperior from './components/NavSuperior/NavSuperior';
import Navbar from './components/Navbar/Navbar';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ModoProvider } from './components/ModoContext/ModoContex'; // Asegúrate de importar el contexto aquí
import ItemDetailContainer from './components/ItemDetailContainer/ItemDetailContainer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="content-wrap">
          {/* Contenedor para el contenido principal */}
          <NavSuperior />
          <ModoProvider>
            {/* Envuelve Navbar y Comunicados con ModoProvider */}
            <Navbar />
            <Routes>
              <Route path='/' element={<Contenedor />} />
              <Route path='/comunicados/' element={<Comunicados />} />
              <Route path='/item/:idItem'  element={<ItemDetailContainer/>}/>
            </Routes>
          </ModoProvider>
          {/* Footer va aquí */}
          <Footer />
        </div>
      </BrowserRouter>



    </div>
  );
}

export default App;
