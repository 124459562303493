import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [modo, setModo] = useState(false);

  const cambiarModo = () => {
    setModo(!modo);
    
  }

  const estilo = modo ? "oscuro" : "claro";
  const icono = modo ? "fa-moon" : "fa-sun";

  return (
    <div className='barra'>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link to={"/"} className='logolink'>
            <img src="../../../image/asijemin.png" alt="ASIJEMIN" className='logonavbar' />
          </Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav cont-nav">
              <li className="navbar-nav">
                <NavLink to="/" className={'link_principales'} >Inicio</NavLink>
              </li>
              <li className="navbar-nav">
                <NavLink className={'link_principales'} to="/comunicados" props={estilo}>Comunicados</NavLink>
              </li>
              <li className="navbar-nav">
                <button type="button" className="btn btn-outline-secondary btn-sm" onClick={cambiarModo}>
                  <i className={`fa-regular ${icono}`}></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar;
